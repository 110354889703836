@import '../../../../../resources/colors-and-fonts';

.selected-locations-menu-container {
  display: none;

  &.visible {
    display: block;
  }

  .selected-locations-menu {
    width: 336px;
    position: relative;
    z-index: 5;
    padding: 24px 16px 16px 16px;
    background-color: $tile_background_color;
    box-shadow: $default_box_shadow;
    font-weight: $font_weight_normal;
    color: $light_text;

    .menu-title {
      font-weight: $font_weight_bold;
      font-size: 20px;
      line-height: 30px;
      margin-bottom: 16px;
    }

    .menu-subtitle {
      font-weight: $font_weight_bold;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 3px;
    }

    .menu-totals-percentage {
      display: flex;
      border-bottom: 1px solid $kashmir_blue;

      .map-point-icon-counts {
        display: flex;
      }

      .percentage {
        margin-left: auto;
        font-weight: 600;
        font-size: 20px;
        line-height: 30px;
      }
    }

    .menu-totals-counts {
      border-bottom: 1px solid $kashmir_blue;
      margin-top: 8px;

      .menu-totals-counts-row {
        display: flex;
        align-items: center;

        &:not(:last-child) {
          border-bottom: 1px solid $border_color_dark;
        }

        .distance-level {
          display: flex;
          align-items: center;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          padding: 8px 0;

          .isochrone-polygon-symbol {
            margin-right: 8px;
          }
        }

        .coverage-value {
          margin-left: auto;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    .selected-points-array {
      overflow: hidden;

      .selected-points-scroll {
        margin-top: 8px;
        max-height: 220px;
        overflow-x: hidden;

        .selected-point {
          display: flex;
          align-items: center;
          text-align: center;
          padding: 8px 0;

          &:hover {
            cursor: pointer;
            color: $selection_color;
          }

          &:not(:last-child) {
            border-bottom: 1px solid $border_color_dark;
          }

          .map-point-details {
            display: flex;
            align-items: center;

            .map-point-icon {
              display: flex;
              align-items: center;
              padding-right: 4px;

              .background-image {
                height: 18px;
                width: 18px;
              }
            }

            .map-point-name {
              text-align: left;
              font-size: 14px;
              font-weight: 400;
            }
          }

          .map-point-coverage {
            display: flex;
            align-items: center;
            margin-left: auto;
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
    }
  }
}
