@import '../../../../../resources/colors-and-fonts';

.distance-polygon-config-menu-wrapper {
  .config-menu {
    width: 336px;
    color: $light_text;
    position: relative;
    z-index: 5;
    background-color: $tile_background_color;
    box-shadow: $default_box_shadow;
    font-style: normal;
    font-size: 14px;

    .config-menu-title {
      padding: 32px 24px 8px 24px;
      display: flex;
      align-items: center;
      line-height: 30px;
      font-size: 20px;
      font-weight: $font_weight_bold;
      border-bottom: 1px solid $border_color_light;
    }

    .config-menu-form {
      padding: 24px;
      border-bottom: 1px solid $border_color_light;
      height: 450px;
      width: 100%;

      .config-menu-choices {
        .choices-title {
          font-weight: 400;
        }

        .config-menu-choices-rows {
          padding-top: 24px;

          .choice-row {
            display: flex;
            padding-bottom: 16px;

            .radio-button {
              margin-left: 0;
            }

            .radio-button-hovered {
              margin-left: 0;
            }

            .choice-text {
              padding-left: 7px;

              .choice-title {
                font-weight: $font_weight_bold;
                margin-bottom: 4px;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }

    .config-menu-buttons {
      padding: 24px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: right;

      .mily-button {
        margin-left: 8px;
      }
    }
  }
}
