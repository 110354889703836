@import '../../../../resources/colors-and-fonts';

.map-marker-popup-menu {
  width: 176px;
  font-family: $font_family;
  font-style: normal;
  font-size: 14px;
  line-height: 21px;
  color: $light_text;

  .menu-row-button {
    background-color: #0b1a2d;
    padding: 8px 16px;

    &:hover {
      color: $selection_color;
      cursor: pointer;
    }
  }
}
