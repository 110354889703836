$deliveries_color: #a931c7;
$pickups_color: #1e7e16;
$deliveries_dim_color: #d29edf;
$pickups_dim_color: #97c594;

$selection_color: #009bfa;
$dim_selection_color: #0072bb80;

//$info_color: #16192f;
$success_color: #4d9a63;
$warning_color: #ca8a2b;
$error_color: #f44336;
$warning_color_dim: #ca8a2b4f;

$dashboard_color_1: #d55607;

$tile_background_color: #0b1a2d;
$tile_background_color_with_opacity: #1a2838;
$background_color: #0b1a2d;

$tile_border_color: #879393;

$dark_text: #16192f;
$light_text: #ece7e5;
$lighter_text: #fff;
$kashmir_blue: #4e6990;

$border_color_light: #2c4159;
$border_color_dark: #133156;

$heatmap_color_1: #1d4877;
$heatmap_color_2: #1b8a5a;
$heatmap_color_3: #fbb021;
$heatmap_color_4: #f68838;
$heatmap_color_5: #ee3e32;

$polygon_border_color: #2c91cf;

$font_family: 'Poppins', sans-serif;
$font_weight_normal: 300;
$font_weight_bold: 500;
//TODO do we need to rename this
$font_weight_input: 400;
$font_wight_form_label: 700;

$default_box_shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.75);
$menu_box_shadow: 0 1px 4px 0 rgba(11, 26, 45, 0.75);

:export {
  deliveries_color: $deliveries_color;
  pickups_color: $pickups_color;
  selection_color: $selection_color;
  dim_selection_color: $dim_selection_color;

  success_color: $success_color;
  warning_color: $warning_color;
  error_color: $error_color;
  warning_color_dim: $warning_color_dim;

  dashboard_color_1: $dashboard_color_1;

  tile_background_color: $tile_background_color;
  tile_background_color_with_opacity: $tile_background_color_with_opacity;
  background_color: $background_color;

  tile_border_color: $tile_border_color;

  dark_text: $dark_text;
  light_text: $light_text;
  lighter_text: $lighter_text;
  kashmir_blue: $kashmir_blue;

  border_color_dark: $border_color_dark;
  border_color_light: $border_color_light;

  heatmap_color_1: $heatmap_color_1;
  heatmap_color_2: $heatmap_color_2;
  heatmap_color_3: $heatmap_color_3;
  heatmap_color_4: $heatmap_color_4;
  heatmap_color_5: $heatmap_color_5;

  polygon_border_color: $polygon_border_color;

  font_family: $font_family;
  font_weight_normal: $font_weight_normal;
  font_weight_bold: $font_weight_bold;
  font_weight_input: $font_weight_input;

  default_box_shadow: $default_box_shadow;
}

// used to override Amplify login screen colors
:root {
  --amplify-colors-background-primary: #{$tile_background_color_with_opacity};
  --amplify-components-fieldcontrol-focus-border-color: #{$kashmir_blue};
  --amplify-components-button-focus-border-color: #{$kashmir_blue};
  --amplify-components-button-border-color: #{$kashmir_blue};
  --amplify-components-button-color: #{$kashmir_blue};
  --amplify-components-button-hover-border-color: #{$kashmir_blue};
  --amplify-components-button-hover-color: #{$kashmir_blue};
  --amplify-components-button-focus-color: #{$kashmir_blue};
  --amplify-components-button-hover-background-color: #{$tile_background_color_with_opacity};
}
