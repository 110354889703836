@import '../../../../resources/colors-and-fonts';

.info-message-wrapper {
  position: absolute;
  top: 24px;
  left: 513px;
  display: none;
  z-index: 10;
  border-radius: 4px;
  padding: 15px;
  color: $tile_background_color;


  &.visible {
    display: flex;
  }

  &.successful {
    background-color: #a1d0af;
    border: 1px solid $success_color;
  }

  &.warning {
    background-color: #f4d7ac;
    border: 1px solid $warning_color;
  }

  &.error {
    background-color: #f5b9b5;
    border: 1px solid $error_color;
  }

  &.info {
    background-color: #abddff;
    border: 1px solid #42a7eb;
  }

  .icon {
    font-size: 24px;
  }

  .icon-check-circle {
    color: $success_color;
  }

  .icon-warning-triangle {
    color: $warning_color;
  }

  .icon-warning-circle {
    color: $error_color;
  }

  .icon-info {
    color: #42a7eb;
  }

  .message {
    margin-left: 10px;
    margin-right: 10px;
    white-space: pre-line;
  }

  .icon-close {
    font-size: 24px;
  }
}
