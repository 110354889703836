@import '../../../../../resources/colors-and-fonts';

.coverage-menu-container {
  .coverage-menu {
    width: 336px;
    position: relative;
    z-index: 5;
    padding: 24px 16px;
    background-color: $tile_background_color;
    box-shadow: $default_box_shadow;
    font-style: normal;
    color: $light_text;

    .coverage-menu-title {
      font-weight: $font_weight_bold;
      font-size: 20px;
      display: flex;
      align-items: center;
      line-height: 30px;
      margin-bottom: 28px;
    }

    .coverage-map-points-list {
      display: flex;
      align-items: center;
      font-weight: $font_weight_bold;
      font-size: 16px;
      padding-bottom: 8px;
      border-bottom: 1px solid $kashmir_blue;
      margin-bottom: 16px;

      .visible-coverage-point-array {
        display: flex;
        margin-left: 14px;
      }
    }

    .coverage-menu-table-titles {
      display: flex;
      font-weight: 600;
      font-size: 15px;

      .coverage-menu-table-title  {
        padding-bottom: 4px;
        width: 50%;

        &:not(:first-child) {
          margin-left: 16px;
        }
      }
    }

    .coverage-data-tables {
      display: flex;
      margin-bottom: 8px;
    }

    .impactful-points {
      display: grid;
      grid-template-rows: 0fr;
      transition: grid-template-rows 500ms;

      &.expanded {
        grid-template-rows: 1fr;
      }

      .animation-container {
        overflow: hidden;
      }
    }
  }
}
