@import '../../../../../resources/colors-and-fonts';

$animation: all 0.2s ease-out;

.impactful-locations-table {
  padding-top: 16px;

  .impactful-locations-title {
    height: auto;
    display: flex;
    align-items: center;
    font-weight: $font_weight_bold;
    font-size: 16px;
    width: 100%;
    border-bottom: 1px solid $kashmir_blue;
    line-height: 24px;

    .circle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 12px;
      height: 12px;
      margin-right: 6px;
      border-radius: 50%;
      font-weight: $font_weight_bold;
      font-size: 15px;
      color: $lighter_text;


      &.most {
        background-color: #4d9a63;
      }

      &.least {
        background-color: #f44336;
      }
    }

    .arrow-wrapper {
      margin-left: auto;

      &:hover {
        cursor: pointer;
        color: $selection_color;
      }

      .icon {
        font-size: 24px;
      }
    }
  }

  .locations-list {
    display: grid;
    grid-template-rows: 0fr;
    transition: $animation;

    &.expanded {
      grid-template-rows: 1fr;
    }

    .animation-container {
      overflow: hidden;
      display: block;

      .location-row {
        display: flex;
        align-items: center;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        padding: 10px 0 8px 0;

        &:not(:last-child) {
          border-bottom: 1px solid $border_color_dark;
        }

        &:hover {
          cursor: pointer;
          color: $selection_color;
        }

        .ordinal-number {
          left: 0;
          padding-right: 2px;
        }

        .location-coverage-count {
          margin-left: auto;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}
